import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from '../scss/page.scss'
import Section from "../components/section/section";
import {graphql, useStaticQuery} from "gatsby";
import {get} from "lodash";

class AboutUs extends React.Component {
  constructor(props) {
    super(props)
    const title = 'About us | Therapetic'
    this.seo = {
      title: title,
      description: '',
      seoTitle: title,
    }
  }
  render() {
    const { data } = this.props
    const ctaUrl = `${get(data, 'site.siteMetadata.certapetInfo.url.ctaUrl')}`
    return (
      <Layout containerClassNames={`page page-about-us`}>
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <Section className="about-us py-2 px-0">
          <div className="row">
            <div className='col-10 col-md-6 mt-5 mx-auto my-md-5'>
              <h1 className="text-lg-center text-md-left">About Us</h1>
              <br/>
              <h2>About Therapetic:</h2>
              <br/>
              <p>Our vision is simple... real therapists providing real comfort so real people can live a better life.</p>
              <br/>
              <p>At its core, Therapetic is an online telehealth platform that connects individuals to licensed mental health professionals in their area focusing on animal-assisted treatment. Our mission is two parts.</p>
              <br/>
              <ol>
                <li>To educate people about mental health and the affect animal-assisted therapy can have for a person who suffers from a mental and/or emotional disability.</li>
                <li>To create the bridge between patient and therapist. In simple, to do the hard part for you.</li>
              </ol>
              <br/>
              <p>Mental health is a vital key to our well-being and when people have a mental and/or emotional disability, their lives as a whole can suffer.</p>
              <br/>
              <p>Not so fun fact: 1 in 5 people in the US experience mental illness every year.</p>
              <br/>
              <p>And unfortunately, only ~43% of adults with mental illness received treatment in 2018. That means less than half of people who suffered from a mental disability got the help they deserved.</p>
              <br/>
              <p>So that’s why it’s Therapetic’s goal to increase the number of people who receive treatment. And for some, the treatment can be as simple as an emotional support animal.</p>
              <br/>
              <p>We listened and we saw many people out there struggled to get help for their mental health. Be it because finding a therapist in their area was difficult or expensive or too much out of their comfort zone to see one in person. There were many. And they were hard to argue with.</p>
              <br/>
              <p>Therapetic cuts out all of the noise and difficulty for you by creating an easy, simple, and clear way to reach out and get what you need to live a better life.</p>
              <br/>
              <p>Our process is as follows:</p>
              <br/>
              <ol>
                {/*<li>Take a short and <a href={`${ctaUrl}&utm_campaign=aboutus&utm_content=cta1`} target="_blank">free pre-screening test</a> to see if you qualify for an emotional support animal.</li>*/}
                <li>Take a short and <a href="/screening">free pre-screening test</a> to see if you qualify for an emotional support animal.</li>
                <li>If you qualify, we connect you to a qualified and licensed mental health professional who will then consult with you the proper treatment option.</li>
                <li>If your therapist agrees you would benefit from an emotional support animal, then they will write you an ESA letter where you can fly or live with your furry support system.</li>
              </ol>
              <br/>
              <p>Unlike most online emotional support animal letter sites that automatically approve you or send you a certificate and not a letter, here at Therapetic we connect you to people who 1) are licensed to write an emotional support animal letter and 2) know what they are talking about. </p>
              <br/>
              <p>Our therapist’s main job is to help you. This is your journey and we want to do all that we can to make the process easy to understand and use.</p>
              <br/>
              <p>Our services are available in all 50 states and available in the following Canadian provinces: Ontario, New Newfoundland and Labrador, Alberta and British Columbia.</p>
              <br/>
              <p>If you have any questions, visit our <a href="/emotional-support-animal-laws">FAQ page</a> or <a href="/customer-support">contact an agent here</a>.</p>
            </div>
          </div>
        </Section>
      </Layout>
    )
  }
}


export default (props) => {
  const data = useStaticQuery(graphql`
    query AboutUs {
      site {
        siteMetadata {
          certapetInfo {
            url {
              ctaUrl
            }
          }
        }
      } 
    } 
  `)
  return (
    <AboutUs  {...props} data={data}/>
  )
};
